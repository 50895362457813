<template>
  <BCard>
    <div class="text-black font-medium text-2xl">
      {{ title }}
    </div>
    <Card ref="cardComponent" />

    <div class="block">
      <div class="w-full flex flex-wrap justify-end gap-[4px]">

        <BButton
          variant="outline-primary"
          size="sm"
          class="w-[130px] shrink-0 filter-button"
          @click="openModalDownload"
        >
          <div class="flex flex-row-reverse items-center justify-between h-full w-full">
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
              alt="download"
              width="20"
              height="20"
            >
            <span class="leading-[1.2]">Download</span>
          </div>
        </BButton>

        <BButton
          id="user-type"
          variant="flat-dark"
          class="w-fit shrink-0 filter-button outlined-filter-button"
        >
          <div class="flex flex-row-reverse gap-[24px] items-center justify-between h-full w-full">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              alt="arrow-down"
            >
            <span class="leading-[1.2] truncate">{{ filterUserClaim === 'Semua' ? 'Level User' : filterUserClaim }}</span>
          </div>
        </BButton>

        <BPopover
          ref="filter-user-type"
          target="user-type"
          triggers="focus"
          placement="bottom"
          custom-class="wrapper-shipment"
        >
          <b-list-group>
            <b-list-group-item
              v-for="user in userClaims"
              :key="user"
              class="cursor-pointer"
              :active="filterUserClaim === user"
              @click="changeFilterUserClaim(user)"
            >
              {{ user }}
            </b-list-group-item>
          </b-list-group>
        </BPopover>

        <BButton
          id="claim-type"
          variant="flat-dark"
          class="w-fit shrink-0 filter-button outlined-filter-button"
        >
          <div class="flex flex-row-reverse gap-[24px] items-center justify-between h-full w-full">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              alt="arrow-down"
            >
            <span class="leading-[1.2] truncate">{{ filterStatusClaim === 'Semua' ? 'Jenis Claim' : filterStatusClaim }}</span>
          </div>
        </BButton>

        <BPopover
          ref="filter-claim-type"
          target="claim-type"
          triggers="focus"
          placement="bottom"
          custom-class="wrapper-shipment"
        >
          <b-list-group>
            <b-list-group-item
              v-for="claim in statusClaims"
              :key="claim"
              class="cursor-pointer"
              :active="filterStatusClaim === claim"
              @click="changeFilterStatusClaim(claim)"
            >
              {{ claim }}
            </b-list-group-item>
          </b-list-group>
        </BPopover>

        <DateRangePicker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          opens="center"
          class="w-[130px] shrink-0 filter-button outlined-filter-button"
          control-container-class="periode-datepicker"
        >
          <template
            v-slot:input
          >
            <div class="flex flex-row-reverse items-center justify-between h-full w-full truncate ...">
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
                alt="arrow-down"
              >
              <span>Periode</span>
            </div>
          </template>
        </DateRangePicker>

        <BButton
          id="popoverShipment"
          variant="flat-dark"
          class="w-[130px] shrink-0 filter-button outlined-filter-button"
        >
          <div class="flex flex-row-reverse items-center justify-between h-full w-full">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              alt="arrow-down"
            >
            <span class="leading-[1.2]">Ekspedisi</span>
          </div>
        </BButton>

        <BPopover
          target="popoverShipment"
          triggers="hover"
          custom-class="wrapper-shipment"
          placement="bottom"
        >
          <b-list-group
            v-for="(item, index) in shipmentList"
            :key="index + 1"
          >
            <b-list-group-item
              :class="item.shipping_name === filterShipment ? 'cursor-pointer wrapper-list-shipping-active' : 'cursor-pointer wrapper-list-shipping'"
              @click="filterByShipment(item)"
            >
              {{ item.shipping_name }}
            </b-list-group-item>
          </b-list-group>
        </BPopover>

        <BButton
          id="popoverStatus"
          variant="flat-dark"
          class="w-[130px] shrink-0 filter-button outlined-filter-button"
        >
          <div class="flex flex-row-reverse items-center justify-between h-full w-full">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              alt="arrow-down"
            >
            <div class="flex gap-[4px]">
              <span class="leading-[1.2]">Semua</span>

              <div
                v-if="filterStatus.length !== 0"
                class="text-primary"
              >
                ({{ filterStatus.length }})
              </div>
            </div>
          </div>
        </BButton>

        <BPopover
          target="popoverStatus"
          triggers="focus"
          placement="bottom"
        >
          <div
            v-for="(item, index) in statusType"
            :key="index + 1"
            class="p-50"
          >
            <BFormCheckbox
              v-model="item.onCheck"
              class="custom-control-primary mb-1"
              @change="filterByStatus(item)"
            >
              <span class="text-black">
                {{ item.label }}
              </span>
            </BFormCheckbox>
          </div>
        </BPopover>

        <BFormInput
          v-model="search"
          placeholder="Cari nama, no tiket dan no resi"
          class="filter-button search-field"
          @input="searchData"
        />
      </div>
    </div>

    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        style="min-height: 300px;"
      >
        <template #cell(ticket_no)="data">
          <div
            v-if="data.item.user_level !== null && data.item.user_level !== 0"
            :class="levelUser.find(item => item.level === 1).level === data.item.user_level ? 'bg-[#DCF3EB] text-[#34A770] border-[#34A770]' : 'bg-[#FEF2CD] text-[#FBBC05] border-[#FBBC05]'"
            class="border-solid border-2 px-1 py-[5px] rounded-full d-flex mb-1 w-fit"
          >
            <img
              class="w-6"
              :src="levelUser.find(item => item.level === data.item.user_level).image"
              :alt="levelUser.find(item => item.level === data.item.user_level).name"
            >
            <span class="align-self-center ml-[5px] text-sm">{{ levelUser.find(item => item.level === data.item.user_level).name }}</span>
          </div>
          <span
            v-if="data.value !== ''"
            @click="handleDetailTiket(data)"
          >
            <div
              class="font-bold"
              style="color: #f95031; cursor: pointer; text-decoration: underline;"
            >{{ data.value }}</div>
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(created_at)="data">
          <div
            class="text-black"
            style="font-size: 13px;"
          >
            {{ DAY_MONTH_YEAR(data.value) }}
          </div>
          <div style="color: #828282; font-size: 12px;">
            {{ TIME(data.value) }} WIB
          </div>
        </template>
        <template #cell(nominal)="data">
          <span class="text-black">{{ IDR(data.value) }}</span>
        </template>
        <template #cell(claim_proof)="data">
          <img
            src="https://storage.googleapis.com/komerce/assets/svg/eye.svg"
            alt="Komerce"
            style="margin: auto; cursor: pointer"
            @click="showProove(data.item)"
          >
        </template>
        <template #cell(claim_type)="data">
          <div class="d-flex flex-column">
            <span class="text-[#E31A1A]">
              {{ showTypeClaim(data.item.claim_type, 'title') }}
            </span>
            <span class="text-[12px] text-[#626262]">
              {{ showTypeClaim(data.item.claim_type, 'subtitle') }}
            </span>
          </div>
        </template>
        <template #cell(shipping)="data">
          <img
            :src="expeditionList.find(item => item.shipping_name === data.value).image_path"
            alt="Komerce"
            style="width: 50px; margin: auto"
          >
        </template>
        <template #cell(user_fullname)="data">
          <div class="flex items-center">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              style="cursor: pointer"
              @click="handlePhone(data.item.no_hp)"
            >
            <span class="text-black">{{ data.value }}</span>
          </div>
        </template>
        <template #cell(order_id)="data">
          <span
            style="color: #08a0f7; cursor: pointer"
            @click="handleDetailOrder(data.value)"
          >Detail Order</span>
        </template>
        <template #cell(status_claim)="data">
          <BDropdown
            v-if="data.value === 0"
            size="sm"
            toggle-class="return-claim-badge claim-badge-disetujui"
            no-caret
          >
            <template #button-content>
              <div class="flex items-center justify-between w-[132px]">
                <span>Nunggu Review</span>
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                  alt="close"
                  width="14"
                  height="14"
                >
              </div>
            </template>
            <BDropdownItem
              @click="handleClickApproveStatus(data)"
            >
              <div class="flex gap-[1rem]">
                <span class="k-Checklist text-[#007F00] border-[1px] border-[#007F00] rounded-full align-self-center" />
                <span>Disetujui</span>
              </div>
            </BDropdownItem>
            <BDropdownItem
              @click="handleClickRejectStatus(data)"
            >
              <div class="flex gap-[1rem]">
                <span class="k-close text-[#E31A1A] border-[1px] border-[#E31A1A] rounded-full align-self-center" />
                <span>Ditolak</span>
              </div>
            </BDropdownItem>
          </BDropdown>
          <div
            v-else-if="data.value === 1"
            v-b-tooltip.hover.top="`${getTooltips(data.item)}`"
            class="return-claim-badge items-center inline-flex w-[100px] gap-[4px] cursor-pointer"
            style="color: #34a770; border: 1px solid #34a770;"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/tick-circle.svg"
              alt="Komerce"
              width="14"
              height="14"
            >
            <span class="text-[12px]">Disetujui</span>
          </div>
          <BDropdown
            v-if="data.value === 2"
            v-b-tooltip.hover.window.top="{ customClass: 'max-h-[100px!important] overflow-y-auto overfow-x-hidden'}"
            :title="`${getTooltips(data.item)}`"
            text="Ditolak"
            size="sm"
            variant="outline-danger"
            menu-class="return-claim-menu"
            toggle-class="return-claim-badge"
            no-caret
          >
            <template #button-content>
              <div class="flex items-center justify-between w-[100px]">
                <div class="flex items-center gap-[4px]">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/close-circle.svg"
                    alt="close"
                    width="14"
                    height="14"
                  >
                  <span>Ditolak</span>
                </div>
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-down-1.svg"
                  alt="close"
                  width="14"
                  height="14"
                >
              </div>
            </template>
            <BDropdownItem
              @click="handleClickApproveStatus(data)"
            >
              <div class="flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/Ellipsegreen.svg"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>Disetujui</span>
              </div>
            </BDropdownItem>
          </BDropdown>
        </template>
      </BTable>
      <BRow class="justify-between">
        <BCol
          md="4"
          class="my-1"
        >
          <div
            class="
              bg-light
              d-flex
              justify-content-center
              align-items-center
              p-50
              rounded
            "
          >
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>
        </BCol>
        <BCol
          md="4"
          class="my-1"
        >
          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>

    <ModalApprove
      :ticket-id="ticketId"
      :get-data="getListData"
    />
    <ModalReject
      :id="selectedId"
      :ticket-id="ticketId"
      :get-data="getListData"
      :claim-type="claimType"
    />
    <ModalImage :data="imageUrl" />
    <ModalConfirm
      id="confirm-status-disetujui"
      ref="confirm-status-disetujui"
      :title="`Setujui ${getClaimType()} ${showTypeClaim(claimType, 'subtitle')}`"
      :message="`Sebelumnya kamu telah menolak pengajuan ${getClaimType()} ${showTypeClaim(claimType, 'subtitle')} pada resi ini. Yakin ingin mengupdate menjadi disetujui?`"
      label-confirm-button="Ubah Status Paket"
      label-cancel-button="Kembali"
      :loading-confirm-button="loading"
      @on-click-cancel-button="$bvModal.hide('confirm-status-disetujui')"
      @on-click-confirm-button="handleApproveDelivered()"
    />
    <ModalDownloadClaimRetur :expeditions-list="expeditionList" />

    <!-- Modal Proove -->
    <BModal
      ref="modal-proove"
      no-close-on-esc
      hide-footer
      hide-header
      centered
    >
      <BRow
        class="my-2"
      >
        <BCol
          v-if="prooveItem.type !== ''"
          cols="12"
          class="mb-2 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Lampiran Bukti {{ prooveItem.type.startsWith('rts') ? 'Claim Retur' : 'Claim Diterima' }}
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="mb-2"
        >
          <div class="form-group row align-items-center">
            <label
              class="col-sm-2 col-form-label"
            >Resi</label>
            <div class="col-sm-10">
              <span class="text-black">
                : {{ prooveItem.cnote }}
              </span>
            </div>
          </div>
          <div
            v-if="prooveItem.type !== 'tidak_diterima' && prooveItem.type !== 'rts_tidak_diterima'"
            class="form-group row align-items-center"
          >
            <label
              class="col-sm-2 col-form-label"
            >Video</label>
            <div class="col-sm-10 d-flex align-items-center">
              <span
                :class="prooveItem.video !== '-' && prooveItem.video !== '' ? 'text-info cursor-pointer' : 'text-info'"
                @click="prooveItem.video !== '-' && prooveItem.video !== '' ? openPathVideo(prooveItem.video) : null"
              >
                <span v-if="prooveItem.video !== '-' && prooveItem.video !== ''"><span class="text-black">:</span> {{ `${prooveItem.video.substring(0, 35)}...` }}</span>
                <span
                  v-else
                  class="text-black"
                >: -</span>
              </span>
              <BImg
                v-if="prooveItem.video !== '-' && prooveItem.video !== ''"
                class="ml-1 cursor-pointer"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/copy.svg"
                @click="copyPathVideo(prooveItem.video)"
              />
            </div>
          </div>
          <div class="form-group row align-items-start">
            <label
              class="col-sm-2 col-form-label"
            >Foto</label>
            <div class="col-sm-10">
              <div
                v-if="prooveItem.images.length > 0"
                class="d-flex flex-wrap"
              >
                <div class="mr-50">
                  :
                </div><div
                  v-for="(file, index) in prooveItem.images"
                  :key="index"
                  class="wrapper-preview-file mr-2 mb-2"
                >
                  <b-img
                    :src="file.path"
                    class="img-preview cursor-pointer"
                    width="56"
                    style="border-radius: 12px; height: 56px;"
                    @click="openPreviewProof(file.path)"
                  />
                  <div>
                    <span>
                      ...{{ file.path.slice(-9) }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-else>
                <span class="mr-50">
                  :
                </span>
                <span class="text-info">
                  -
                </span>
              </div>
            </div>
          </div>
        </BCol>

        <BCol cols="12">
          <BButton
            variant="primary"
            block
            style="border-radius: 8px;"
            @click="closeProoveModal"
          >
            Tutup
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <!-- Modal Preview Proof -->
    <BModal
      ref="modal-preview-proof"
      centered
      hide-header
      hide-footer
      size="xl"
    >
      <BRow class="justify-content-center my-4">
        <BImg :src="prooveItem.imagePreviewProof" />
      </BRow>
    </BModal>

    <!-- Modal Approve Delivered -->
    <BModal
      ref="modal-approve-delivered"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      hide-header
      size="md"
      centered
    >
      <BRow class="my-3">
        <BCol
          cols="12"
          class="mb-1 d-flex justify-content-center"
        >
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            width="100"
          />
        </BCol>

        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Setujui
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black">
            Saldo user akan {{ claimType === 'paksa_rts' ? 'berkurang' : 'bertambah' }} ketika kamu merubah status ke
            <strong>
              Disetujui
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="d-flex"
        >
          <BButton
            variant="outline-primary"
            block
            class="mr-1 mt-1"
            style="border-radius: 8px"
            @click="closeModalApproveDeivered"
          >
            Kembali
          </BButton>
          <BButton
            variant="primary"
            class="mt-1"
            block
            style="border-radius: 8px"
            @click="handleApproveDelivered"
          >
            {{ claimType === 'retur' ? 'Setujui' : 'Ubah Status Paket' }}
            <b-spinner
              v-if="loadingClaimDelivered"
              class="ml-50"
              small
            />
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <!-- Modal Rejected Delivered -->
    <BModal
      ref="modal-rejected-delivered"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      hide-header
      size="md"
      centered
      @hidden="notesClaimDelivered = ''"
    >
      <BRow class="my-3">
        <BCol
          cols="12"
          class="mb-1 d-flex justify-content-center"
        >
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            width="100"
          />
        </BCol>

        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Tolak
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black">
            Kamu yakin ingin menolak pengajuan claim ini ?
          </span>
        </BCol>

        <BCol
          cols="12"
          class="mb-1"
        >
          <label for="notes">
            Catatan
          </label>
          <textarea
            id="notes"
            v-model="notesClaimDelivered"
            placeholder="Tuliskan alasan kamu menolak Pengajuan Claim"
            class="form-control"
            rows="8"
          />
        </BCol>

        <BCol
          cols="12"
          class="d-flex"
        >
          <BButton
            variant="outline-primary"
            block
            class="mr-1 mt-1"
            style="border-radius: 8px"
            @click="closeModalRejectedDeivered"
          >
            Batal
          </BButton>
          <BButton
            variant="primary"
            :class="!notesClaimDelivered ? 'mt-1 cursor-not-allowed' : 'mt-1'"
            block
            :style="!notesClaimDelivered ? 'border-radius: 8px; border: 1px solid #E2E2E2!important; background: #E2E2E2!important; color: #C2C2C2!important;' : 'border-radius: 8px'"
            :disabled="!notesClaimDelivered"
            @click="handleRejectedDelivered"
          >
            Tolak
            <b-spinner
              v-if="loadingClaimDelivered"
              class="ml-50"
              small
            />
          </BButton>
        </BCol>
      </BRow>
    </BModal>
  </BCard>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BPopover, BButton, BDropdown, BDropdownItem, BOverlay, BPagination,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  komshipDate,
} from '@/store/helpers'
import { mapState } from 'vuex'
import ModalApprove from './ModalApprove.vue'
import ModalReject from './ModalReject.vue'
import ModalImage from './ModalImage.vue'
import { columnConfig, statusType } from './config'
import ModalDownloadClaimRetur from './ModalDownloadClaimRetur.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Card from './component/Card.vue'

export default {
  components: {
    BCard, BRow, BCol, BFormInput, BFormCheckbox, BPopover, BButton, BDropdown, BDropdownItem, ModalApprove, BOverlay, BPagination, ModalReject, ModalImage, ModalConfirm, ModalDownloadClaimRetur, DateRangePicker, Card,
  },
  data() {
    return {
      title: 'Pengajuan Claim',
      search: '',
      imageUrl: [],
      filterStatus: [],
      statusType,
      items: [],
      fields: columnConfig,
      loading: true,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [50, 100, 200],
      totalPerPage: 50,
      ticketId: 0,
      selectedId: 0,
      IDR,
      DAY_MONTH_YEAR,
      TIME,
      shipmentList: [],
      filterShipment: '',
      ranges: {
        'Semua ': [komshipDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      statusClaims: ['Semua', 'Claim Paket Retur', 'Claim Paket Hilang', 'Delivered - Paket Rusak', 'Delivered - Paket Tidak Diterima', 'RTS - Paket Rusak', 'RTS - Paket Tidak Diterima', 'Laporan - Paket Retur Diterima (RTS)', 'Laporan - Paket Diterima (Delivered)'],
      filterStatusClaim: 'Semua',
      userClaims: ['Semua', 'User Prioritas'],
      filterUserClaim: 'Semua',
      claimType: '',

      prooveItem: {
        cnote: '',
        video: '',
        images: [],
        type: '',
        imagePreviewProof: '',
      },
      claimTypeDelivered: '',
      claimIdDelivered: '',
      notesClaimDelivered: '',
      loadingClaimDelivered: false,
      claimText: {},

      levelUser: [
        {
          level: 1,
          image: 'https://storage.googleapis.com/komerce/assets/icons/badge_juragan.svg',
          name: 'Juragan',
        },
        {
          level: 2,
          image: 'https://storage.googleapis.com/komerce/assets/svg/Saudagar.svg',
          name: 'Saudagar',
        },
      ],
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
  },
  watch: {
    currentPage: {
      handler() {
        this.getListData()
      },
    },
    dateRange() {
      this.getListData()
    },
    filterStatusClaim() {
      this.getListData()
    },
    filterUserClaim() {
      this.getListData()
    },
  },
  mounted() {
    if (this.expeditionList.length === 0) {
      this.$store.dispatch('filter/getUpdateExpedition')
    }
    this.initialize()
  },
  methods: {
    initialize() {
      this.getListData()
      this.getShipment()
      this.$refs.cardComponent.getReturInfo()
    },
    async getListData() {
      this.loading = true
      const params = this.buildQueryParams()
      const url = '/komship/api/v1/admin/claims/list'
      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data.data
          this.items = data
          this.totalRows = res.data.data.total_data
          this.loading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    handlePhone(value) {
      window.open(`https://wa.me/62${value.substring(1)}`, '_blank')
    },
    filterByStatus(data) {
      const findIndexObj = this.statusType.findIndex(
        items => items.value === data.value,
      )
      const findObj = this.filterStatus.findIndex(items => items === data.value)
      if (this.statusType[findIndexObj].onCheck === true) {
        this.filterStatus.push(data.value)
      } else {
        this.filterStatus.splice(findObj, 1)
      }
      this.getListData()
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getListData()
    },
    setTickedId(value) {
      this.ticketId = value
    },
    searchData: _.debounce(function search() {
      this.getListData()
    }, 1000),
    handleDetailOrder(value) {
      const routeData = this.$router.resolve({
        path: `/tiket/detail/order/${value}`,
      })
      window.open(routeData.href, '_blank')
    },
    handleDetailTiket(value) {
      const ticketId = value.item.ticket_id
      const routeData = this.$router.resolve({
        path: `/tiket/detail/${ticketId}`,
      })
      window.open(routeData.href, '_blank')
    },
    setUrlImage(value) {
      this.imageUrl = value
    },
    getShipment() {
      komshipAxiosIns.get('/v1/ticket-admin/shipment/list').then(response => {
        this.shipmentList = response.data.data.map(item => ({
          ...item,
          onCheck: false,
        }))
      }).catch(err => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    filterByShipment(data) {
      this.filterShipment = data.shipping_name
      this.getListData()
    },
    async handleChangeOrderStatus(statusClaim = 1) {
      this.loading = true
      const url = `/v1/ticket-admin/handling-claim-retur/${this.ticketId}?status_claim=${statusClaim}`
      await komshipAxiosIns
        .post(url)
        .then(() => {
          this.$toast_success({ message: 'Sukses update claim retur' })
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal update claim retur' })
        })
        .finally(() => {
          this.loading = false
          this.$bvModal.hide('confirm-status-disetujui')
          this.initialize()
        })
    },
    formatDateParams(date) {
      return moment(date)
        .format('YYYY-MM-DD')
    },
    buildQueryParams() {
      const params = {
        status_claim: this.filterStatus.join(),
        shipping: this.filterShipment,
        total_per_page: this.totalPerPage,
        page: this.currentPage,
        search: this.search,
      }

      if (this.filterUserClaim === 'User Prioritas') {
        params.user_level = '1,2'
      }

      if (this.dateRange.startDate) {
        params.start_date = this.formatDateParams(this.dateRange.startDate)
      }

      if (this.dateRange.endDate) {
        params.end_date = this.formatDateParams(this.dateRange.endDate)
      }

      if (this.filterStatusClaim !== 'Semua') {
        params.claim_type = this.filterStatusClaim === 'Claim Paket Retur' ? 'retur' : 'hilang'
        if (this.filterStatusClaim === 'Claim Paket Retur') {
          params.claim_type = 'retur'
        }
        if (this.filterStatusClaim === 'Claim Paket Hilang') {
          params.claim_type = 'hilang'
        }
        if (this.filterStatusClaim === 'Delivered - Paket Rusak') {
          params.claim_type = 'rusak'
        }
        if (this.filterStatusClaim === 'Delivered - Paket Tidak Diterima') {
          params.claim_type = 'tidak_diterima'
        }
        if (this.filterStatusClaim === 'RTS - Paket Rusak') {
          params.claim_type = 'rts_rusak'
        }
        if (this.filterStatusClaim === 'RTS - Paket Tidak Diterima') {
          params.claim_type = 'rts_tidak_diterima'
        }
        if (this.filterStatusClaim === 'Laporan - Paket Diterima (Delivered)') {
          params.claim_type = 'paksa_diterima'
        }
        if (this.filterStatusClaim === 'Laporan - Paket Retur Diterima (RTS)') {
          params.claim_type = 'paksa_rts'
        }
      }

      return params
    },
    handleClickApproveStatus(data) {
      this.setTickedId(data.item.ticket_id)
      this.selectedId = data.item.id
      this.claimType = data.item.claim_type
      this.claimIdDelivered = data.item.id

      const canApproveIfEndOrder = ['Retur', 'Diterima']
      const canApproveAfterEndOrder = ['retur', 'rusak', 'tidak_diterima', 'rts_rusak', 'rts_tidak_diterima']

      if (data.item.is_end_order === 0) {
        this.$refs['modal-approve-delivered'].show()
      } else if (data.item.is_end_order === 1 && canApproveIfEndOrder.includes(data.item.order_status) && canApproveAfterEndOrder.includes(data.item.claim_type)) {
        this.$refs['modal-approve-delivered'].show()
      } else if (data.item.status_claim === 2 && data.item.is_end_order === 0) {
        this.$bvModal.show('confirm-status-disetujui')
      } else {
        this.$toast_error({ message: `Gagal menyetujui claim karena status order sudah "${this.getClaimType()} ${this.showTypeClaim(data.item.claim_type, 'subtitle')}"` })
      }
    },
    handleClickRejectStatus(data) {
      this.setTickedId(data.item.ticket_id)
      this.claimType = data.item.claim_type
      this.selectedId = data.item.id

      const nonRejectableClaims = ['rusak', 'tidak_diterima', 'rts_tidak_diterima', 'rts_rusak', 'paksa_diterima', 'paksa_rts', 'hilang', 'retur']

      if (!nonRejectableClaims.includes(data.item.claim_type)) {
        this.$bvModal.show('modalReject')
      } else {
        this.$refs['modal-rejected-delivered'].show()
      }
    },
    changeFilterStatusClaim(nextValue) {
      this.filterStatusClaim = nextValue
      this.$refs['filter-claim-type'].$emit('close')
    },
    changeFilterUserClaim(nextValue) {
      this.filterUserClaim = nextValue
      this.$refs['filter-user-type'].$emit('close')
    },
    showProove(data) {
      this.prooveItem.cnote = data.cnote
      const filterImage = data.claim_proof.filter(item => item.type === 'image')
      this.prooveItem.images = filterImage
      const findPathVideo = data.claim_proof.find(item => item.type === 'video')
      this.prooveItem.video = findPathVideo ? findPathVideo.path : '-'
      this.prooveItem.type = data.claim_type
      this.$refs['modal-proove'].show()
    },
    closeProoveModal() {
      this.$refs['modal-proove'].hide()
    },
    openPreviewProof(data) {
      window.open(data, '_blank')
    },
    showTypeClaim(data, part) {
      const claimTypeMap = {
        rusak: { title: 'Claim Delivered', subTitle: 'Paket Rusak' },
        tidak_diterima: { title: 'Claim Delivered', subTitle: 'Paket Tidak Diterima' },
        retur: { title: 'Claim', subTitle: 'Paket Retur' },
        hilang: { title: 'Claim', subTitle: 'Paket Hilang' },
        rts_rusak: { title: 'Claim RTS', subTitle: 'Paket Rusak' },
        rts_tidak_diterima: { title: 'Claim RTS', subTitle: 'Paket Tidak Diterima' },
        paksa_diterima: { title: 'Laporan', subTitle: 'Paket Diterima (Delivered)' },
        paksa_rts: { title: 'Laporan', subTitle: 'Paket Retur Diterima (RTS)' },
        diterima: { title: 'Laporan', subTitle: 'Paket Diterima' },
      }

      if (claimTypeMap[data]) {
        if (part === 'title') {
          return claimTypeMap[data].title
        } if (part === 'subtitle') {
          return claimTypeMap[data].subTitle
        }
      }

      return '-'
    },
    closeModalApproveDeivered() {
      this.$refs['modal-approve-delivered'].hide()
    },
    handleApproveDelivered() {
      this.loadingClaimDelivered = true
      const params = {
        status: 1,
        type: this.claimType,
        notes: this.notesClaimDelivered,
      }
      newAxiosIns.post(`/komship/api/v1/claims/confirm/${this.selectedId}`, params).then(() => {
        this.loadingClaimDelivered = false
        this.$toast_success({ message: `Berhasil menyetujui ${this.getClaimType()} ${this.showTypeClaim(this.claimType, 'subtitle')}` })
        this.initialize()
        this.$refs['modal-approve-delivered'].hide()
        this.$bvModal.hide('confirm-status-disetujui')
      }).catch(() => {
        this.loadingClaimDelivered = false
        this.$toast_error({ message: `Gagal menyetujui ${this.getClaimType()} ${this.showTypeClaim(this.claimType, 'subtitle')}` })
      })
    },
    getClaimType() {
      if (this.claimType === 'rusak' || this.claimType === 'tidak_diterima') {
        return 'Claim Delivered'
      } if (this.claimType === 'rts_rusak' || this.claimType === 'rts_tidak_diterima') {
        return 'Claim RTS'
      } if (this.claimType === 'retur' || this.claimType === 'hilang') {
        return 'Claim'
      } if (this.claimType === 'paksa_diterima' || this.claimType === 'paksa_rts') {
        return 'Laporan'
      }
      return ''
    },
    closeModalRejectedDeivered() {
      this.$refs['modal-rejected-delivered'].hide()
    },
    handleRejectedDelivered() {
      this.loadingClaimDelivered = true
      const params = {
        status: 2,
        type: this.claimType,
        notes: this.notesClaimDelivered,
      }
      newAxiosIns.post(`/komship/api/v1/claims/confirm/${this.selectedId}`, params).then(() => {
        this.loadingClaimDelivered = false
        this.$toast_success({ message: `Berhasil menolak ${this.getClaimType()} ${this.showTypeClaim(this.claimType, 'subtitle')}` })
        this.initialize()
        this.$refs['modal-rejected-delivered'].hide()
        this.notesClaimDelivered = ''
      }).catch(() => {
        this.loadingClaimDelivered = false
        this.$toast_error({ message: `Gagal menolak ${this.getClaimType()} ${this.showTypeClaim(this.claimType, 'subtitle')}` })
      })
    },
    copyPathVideo(data) {
      navigator.clipboard.writeText(data)
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: `${data} berhasil disalin`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        1000,
      )
    },
    openPathVideo(data) {
      window.open(data, '_blank')
    },
    openModalDownload() {
      this.$bvModal.show('modal-download-claim-retur')
    },
    getTooltips(item) {
      const status = item.status_claim === 1 ? 'Disetujui' : 'Ditolak'
      const notes = item.notes ? item.notes : '-'
      const approvalBy = item.log_activity ? item.log_activity.full_name : '-'

      return `${status} oleh ${approvalBy} pada : ${DAY_MONTH_YEAR(item.updated_at)}. ${item.status_claim === 2 ? notes : ''}`
    },
  },
}
</script>

<style>
  .wrapper-shipment > .popover-body {
    padding: 0!important;
    min-width: 200px;
  }

  .wrapper-list-shipping {
    border: 0!important;
  }

  .wrapper-list-shipping:hover {
    background: #FCD4BE!important;
    color: #F95031;
  }

  .wrapper-list-shipping-active {
    background: #FCD4BE!important;
    color: #F95031;
    border: 0!important;
  }

  .return-claim-badge {
    border-radius: 99px !important;
    padding: 2px 5px !important;
  }

  .return-claim-menu {
    border-radius: 8px !important;
  }

  .claim-badge-disetujui {
    background-color: #E2E2E2 !important;
    border: none !important;
    color: #222222 !important;
    padding: 4px 6px !important;
  }
  .filter-button {
    padding: 6px 12px !important;
    border-radius: 8px !important;
  }

  .outlined-filter-button {
    border: 1px solid rgb(194, 194, 194);
  }

  .search-field {
    width: 220px !important;
    flex-shrink: 0;
  }

  @media screen and (min-width: 1340px) {
    .search-field {
      width: 308px !important;
    }
  }
</style>
